import React, { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { IonDatetime } from "@ionic/react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import http from "axios";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";

interface CodeReportBody {
  start: string;
  end: string;
}

interface CodeReportRecord {
  type: string;
  consumed: number;
  remaining: number;
}

const CodeReport: React.FC<{}> = () => {
  const [startDate, setStartDate] = useState<string>(
    "2025-01-01T00:00:00-00:00"
  );
  const [endDate, setEndDate] = useState<string>("2025-02-01T00:00:00-00:00");
  const [codeReport, setCodeReport] = useState<CodeReportRecord[]>([]);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", {
      page: "admin_code_report",
    });
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const body: CodeReportBody = {
        start: startDate,
        end: endDate,
      };

      http
        .post<CodeReportRecord[]>(`/v1/admin/codereport`, body)
        .then((result) => {
          setCodeReport(result.data);
        })
        .catch((e) => {
          setError(e.message);
        });
    }
  }, [startDate, endDate]);

  return (
    <PageLayout showEnvironment={true}>
      <Title title="Code Report" />
      <div className="flex items-center">
        <p>Consumed Start Date</p>
        <IonDatetime
          displayFormat="YYYY-MM-DD"
          displayTimezone="utc"
          value={startDate}
          onIonChange={(e) => setStartDate(e.detail.value!)}
        />
        <p className="ml-4">Consumed End Date</p>
        <IonDatetime
          displayFormat="YYYY-MM-DD"
          displayTimezone="utc"
          value={endDate}
          onIonChange={(e) => setEndDate(e.detail.value!)}
        />
      </div>
      <ul className="list-none pl-0">
        <div
          style={{
            display: "flex",
            padding: "20px 0px",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "45%",
                display: "inline-block",
                alignItems: "center",
              }}
            >
              Type
            </div>
            <div
              style={{
                width: "25%",
                display: "inline-block",
                alignItems: "center",
              }}
            >
              Consumed In Range
            </div>
            <div
              style={{
                width: "30%",
                display: "inline-block",
                alignItems: "center",
              }}
            >
              Currently Remaining
            </div>
          </div>
          {codeReport.map((record) => (
            <CodeReportRow record={record} />
          ))}
        </div>
      </ul>
      <ErrorMessage isText error={error} />
    </PageLayout>
  );
};

interface CodeReportRowProps {
  record: CodeReportRecord;
}

const CodeReportRow: React.FC<CodeReportRowProps> = ({ record }) => {
  return (
    <div
      style={{ display: "flex" }}
      className={"text-gray-500"}
      key={`${record.type}`}
    >
      <div
        style={{
          width: "45%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">{record.type}</p>
      </div>
      <div
        style={{
          width: "25%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">{record.consumed}</p>
      </div>
      <div
        style={{
          width: "30%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">{record.remaining}</p>
      </div>
    </div>
  );
};

export default CodeReport;
