import React from "react";
import PageLayout from "../../layouts/PageLayout";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";

const AdminContainer: React.FC<{}> = () => {
  return (
    <PageLayout testId="admin-screen" showEnvironment={true}>
      <Title title="Admin Panel" className="mt-7" />
      <NavigationLink to="/admin/manage-accounts" title="Manage Accounts" />
      <NavigationLink to="/admin/manage-logs" title="Manage Logs" />
      <NavigationLink
        to="/admin/add-third-party-codes"
        title="Add Third Party Codes"
      />
      <NavigationLink
        to="/admin/add-redemption-codes"
        title="Add Redemption Codes"
      />
      <NavigationLink to="/admin/code-report" title="Code Report" />
      <NavigationLink to="/admin/bulk-email" title="Bulk Email" />
      <NavigationLink to="/admin/message-accounts" title="Message Accounts" />
    </PageLayout>
  );
};

export default AdminContainer;
