import React, { useCallback, useEffect, useState } from "react";
import { IonToast } from "@ionic/react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import http from "axios";
import firebase from "firebase";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";

interface AddRedemptionCodesBody {
  type: string;
  quantity: number;
}

interface AddRedemptionCodesResponse {
  result: string;
  codes: string[];
}

const AddRedemptionCodes: React.FC<{}> = () => {
  const types = ["Privacy Premium 1 Year"];

  const [type, setType] = useState<string>(types[0]);
  const [quantity, setQuantity] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [codeText, setCodeText] = useState<string>("");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", {
      page: "admin_add_redemption_codes",
    });
  }, []);

  const submit = useCallback(
    (form) => {
      if (form) {
        form.preventDefault();
      }
      firebase.analytics().logEvent("admin_add_redemption_codes", {});
      FirebaseAnalytics.logEvent("admin_add_redemption_codes", {});

      setError("");
      setToastMessage("");
      setCodeText("");

      const body: AddRedemptionCodesBody = {
        type,
        quantity,
      };

      http
        .post<AddRedemptionCodesResponse>(`/v1/admin/addredemptioncodes`, body)
        .then((result) => {
          setQuantity(0);
          setToastMessage(result.data.result);
          setCodeText(result.data.codes.join("\r\n"));
        })
        .catch((e) => {
          setError(e.message);
        });
    },
    [type, quantity]
  );

  return (
    <PageLayout showEnvironment={true}>
      <Title title="Add Redemption Codes" />
      <form onSubmit={(e) => submit(e)}>
        <div className="flex flex-row justify-start items-center w-full gap-3">
          <label
            htmlFor="Code Type Selection"
            className="text-md text-center font-medium my-3 whitespace-nowrap text-gray-500"
          >
            Type
          </label>
          <div className="select-container">
            <select
              id="type"
              data-testid="type-select"
              value={type}
              className="w-full mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
              onChange={(e) => {
                setType(e.currentTarget.value);
              }}
            >
              {types.map((type) => (
                <option
                  data-testid={`codetype-${type}`}
                  key={type}
                  value={type}
                >
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="basis-full">
          <Input
            className="my-4"
            id="quantity"
            data-testid="quantity-input"
            label=""
            inputProps={{
              value: quantity > 0 ? quantity : "",
              onChange: (e: any) => setQuantity(e.target.value),
            }}
            type="text"
            placeholder="Quantity"
          />
        </div>
        <Button type="submit" disabled={quantity === 0} data-testid="submit">
          Create Codes
        </Button>
        <br />
        <Input
          className="my-4"
          id="codeText"
          data-testid="codeText-input"
          label=""
          inputProps={{
            value: codeText,
          }}
          type="text"
          placeholder="Created codes will appear here"
          useTextArea={true}
        />
        <ErrorMessage isText error={error} />
        <IonToast
          color="success"
          isOpen={!!toastMessage.length}
          onDidDismiss={() => setToastMessage("")}
          message={toastMessage}
          duration={5000}
        />
      </form>
    </PageLayout>
  );
};

export default AddRedemptionCodes;
