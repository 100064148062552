import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import AdminContainer from "./AdminContainer";
import ManageAccounts from "./ManageAccounts";
import ManageLogs from "./ManageLogs";
import AddThirdPartyCodes from "./AddThirdPartyCodes";
import AddRedemptionCodes from "./AddRedemptionCodes";
import CodeReport from "./CodeReport";
import BulkEmail from "./BulkEmail";
import MessageAccounts from "./MessageAccounts";

const Routes: React.FC<{}> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/manage-accounts`} exact>
        <ManageAccounts />
      </Route>
      <Route path={`${path}/manage-logs`} exact>
        <ManageLogs />
      </Route>
      <Route path={`${path}/add-third-party-codes`} exact>
        <AddThirdPartyCodes />
      </Route>
      <Route path={`${path}/add-redemption-codes`} exact>
        <AddRedemptionCodes />
      </Route>
      <Route path={`${path}/code-report`} exact>
        <CodeReport />
      </Route>
      <Route path={`${path}/bulk-email`} exact>
        <BulkEmail />
      </Route>
      <Route path={`${path}/message-accounts`} exact>
        <MessageAccounts />
      </Route>
      <Route path={path} exact>
        <AdminContainer />
      </Route>
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default Routes;
